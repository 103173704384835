<template>
  <div>
    <v-row>
      <v-col
        md="6"
        sm="6"
      >
        <v-img
          class="mx-auto rounded seoImg"
          :src="getSeoImg"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="grey-lighten-4"
                indeterminate
              />
            </div>
          </template>
        </v-img>
      </v-col>
      <v-col
        md="6"
        sm="6"
      >
        <div class="text-h5 mb-1">
          {{ item.name }}
        </div>
        <div class="text--primary">
          {{
            (item.description || '').length > 350
              ? `${item.description.length > 350 ? `${item.description.substring(0, 350)}...` : item.description}`
              : item.description
          }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'LandingPageSeo',
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    getSeoImg() {
      return `${this.item.url}/${this.item.image}`
    }
  },
  watch: {},
  mounted () {}
}
</script>
