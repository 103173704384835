<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :form-scheme="formScheme"
          :resource="resource"
          :expanded="expanded"
          :show-expand="showExpand"
          :show-select="false"
          :single-expand="singleExpand"
          :item-class-fn="itemClassFn"
          :allow-default-actions="false"
          :default-table-headers="['actions']"
          :hide-top-action="['create']"
        >
          <template #action-buttons="{ item }">
            <table-btn
              :item="item"
              :tooltip-text="$t('Update template config')"
              :icon="`pencil`"
              :fn="update"
            />
            <table-btn
              v-if="item.status === 'ready'"
              :item="item"
              :tooltip-text="$t('Upload this template')"
              :icon="`folder-upload-outline`"
              :fn="upload"
            />
            <table-btn
              :item="item"
              :loading="item.downloadLoading"
              :tooltip-text="$t('Download this template')"
              :icon="`download`"
              :fn="download"
            />
          </template>
          <template #[`item.status`]="{ item }">
            <v-icon :color="item.status === 'ready' ? 'green' : 'red'">
              {{ item.status === 'ready' ? 'mdi-check' : 'mdi-close' }}
            </v-icon>
          </template>
          <template #[`item.lastChanged`]="{ item }">
            <table-date :date="item.lastChanged" />
          </template>
          <template #[`item.description`]="{ item }">
            {{
              (item.description || '').length > 185
                ? `${item.description.length > 185 ? `${item.description.substring(0, 185)}...` : item.description}`
                : item.description
            }}
          </template>
          <template #[`item.thumb`]="{ item }">
            <v-img
              :src="`${item.thumb}`"
              :lazy-src="`${item.thumb}`"
              aspect-ratio="1"
              class="grey lighten-2 thumb"
              width="70"
              style="cursor: pointer"
              @click="viewScreenshot(item)"
              @error="errorHandlerThumb(item)"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </template>
          <template #[`item.imageUrl`]="{ item }">
            <v-img
              v-if="item.imageUrl"
              :src="`${item.imageUrl}`"
              :lazy-src="`${item.imageUrl}`"
              aspect-ratio="1"
              class="grey thumb ma-auto"
              width="70"
              style="cursor: pointer"
              @click="viewSeoImage(item)"
              @error="errorHandlerSeoImage(item)"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
            <span v-else>&mdash;</span>
          </template>
        </table-data>
        <dialogs-update-landing-page
          :dialog="updateLandingPageDialog"
          :edited-item="updateLandingPageEditedItem"
          @close="updateLandingPageDialogClose"
        />
        <dialogs-upload-landing-page
          ref="uploadLandingPageDialog"
          @close="uploadLandingPageDialogClose"
        />
        <dialog-image ref="imageZoom" />
      </v-col>
      <v-divider />
    </v-row>
  </v-container>
</template>

<script>
import DialogsUpdateLandingPage from './dialogs/UpdateLandingPage.vue'
import DialogsUploadLandingPage from './dialogs/UploadLandingPage.vue'
export default {
  name: 'LandingPagesGenerator',
  components: {
    DialogsUpdateLandingPage,
    DialogsUploadLandingPage
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'tasks.lpg',
    expanded: [],
    showExpand: false,
    singleExpand: false,
    showSelect: false,
    editedItem: {},
    //
    syncLoading: false,
    //
    updateLandingPageDialog: false,
    updateLandingPageEditedItem: {},
    //
    uploadLandingPageDialog: false,
    uploadLandingPageEditedItem: {}
    //
  }),
  metaInfo () {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('tasks.lpg.meta.title')} — %s`
    }
  },
  computed: {
    getEditedItem() {
      return this.$store.getters[`__${this.resource}/getEditedItem`] || []
    },
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    }
  },
  async mounted () {
    this.title = this.$t('tasks.lpg.title')

    this.tableHeaders = [
      {
        text: this.$t('Thumb'),
        align: 'center',
        value: 'thumb',
        width: '90px',
        sortable: false
      },
      {
        text: this.$t('System name'),
        align: 'start',
        value: 'alias'
      },
      {
        text: this.$t('SEO Name'),
        value: 'name'
      },
      {
        text: this.$t('SEO Description'),
        value: 'description'
      },
      {
        text: this.$t('SEO Image'),
        value: 'imageUrl',
        align: 'center',
        width: '90px',
        sortable: false
      },
      {
        text: this.$t('Changed'),
        value: 'lastChanged',
        align: 'center',
        width: '150px'
      },
      {
        text: this.$t('Ready'),
        value: 'status',
        align: 'center',
        width: '100px'
      }
    ]

    this.formScheme = {}
  },
  methods: {
    update(item) {
      this.updateLandingPageEditedItem = Object.assign({},
        {
          id: item.id,
          dir: item.alias,
          seoTitle: item.name,
          seoImg: item.image,
          seoDesc: item.description,
          url: item.url,
          screenshot: item.screenshot,
          status: item.status === 'ready' ? 'ready' : 'notReady'
        })
      this.updateLandingPageDialog = true
    },
    async upload(item) {
      await this.$refs.uploadLandingPageDialog.open(item)

      // this.uploadLandingPageEditedItem = Object.assign({},
      //   {
      //     id: item.id,
      //     dir: item.alias,
      //     seoTitle: item.name,
      //     seoImg: item.image,
      //     seoDesc: item.description,
      //     url: item.url,
      //     screenshot: item.screenshot
      //     //
      //     // token: '',
      //     // affiliate: '',
      //     // base: '',
      //     // thankYouPage: '',
      //     // cdn: '',
      //     // domains: [],
      //     // withPixel: false,
      //     // fbPixel: '',
      //     // gPixel: '',
      //     // compressHtml: false,
      //     // withUpload: false
      //   })
      // this.uploadLandingPageDialog = true
    },
    async download(item) {
      this.$set(item, 'downloadLoading', true)
      const data = {
        name: item.alias,
        mode: 'sourceDownload'
      }
      try {
        const response = await this.$store.dispatch('tasksLpg/generate', { data })
        if (response.success === true) {
          this.$toast.success(response.messages)
          await this.$store.dispatch('adminLayout/toggleDialogEventLog', true)
        } else {
          this.$toast.error(response.errors || response.message)
        }
      } catch (e) {
        this.$toast.error(e.response.data.message)
      } finally {
        this.$set(item, 'downloadLoading', false)
      }
    },
    async viewScreenshot({ screenshot }) {
      await this.$refs.imageZoom.open({ url: screenshot }, { width: '800' })
    },
    async viewSeoImage({ imageUrl }) {
      await this.$refs.imageZoom.open({ url: imageUrl }, { width: '800' })
    },
    updateLandingPageDialogClose() {
      this.updateLandingPageDialog = false
      this.updateLandingPageEditedItem = Object.assign({}, {})
    },
    uploadLandingPageDialogClose() {
      this.uploadLandingPageDialog = false
      this.uploadLandingPageEditedItem = Object.assign({}, {})
    },
    itemClassFn(item) {
      let classes
      if (this.getLockedEntities.indexOf(item.id) > -1) {
        classes += ' blue-grey lighten-5 loading-background '
      }
      if (item.with_error === true) {
        classes += ' red lighten-5 '
      }
      return classes
    },
    errorHandlerThumb(item) {
      item.thumb = require('@/assets/img/70.png')
      return item
    },
    errorHandlerSeoImage(item) {
      item.imageUrl = require('@/assets/img/750x225.png')
      return item
    }

  }
}
</script>

<style lang="scss">
.thumb {
  border: 1px solid #ebebeb;
  padding: 7px !important;
  margin: 7px;
}
</style>
