var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"form-scheme":_vm.formScheme,"resource":_vm.resource,"expanded":_vm.expanded,"show-expand":_vm.showExpand,"show-select":false,"single-expand":_vm.singleExpand,"item-class-fn":_vm.itemClassFn,"allow-default-actions":false,"default-table-headers":['actions'],"hide-top-action":['create']},scopedSlots:_vm._u([{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('Update template config'),"icon":"pencil","fn":_vm.update}}),(item.status === 'ready')?_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('Upload this template'),"icon":"folder-upload-outline","fn":_vm.upload}}):_vm._e(),_c('table-btn',{attrs:{"item":item,"loading":item.downloadLoading,"tooltip-text":_vm.$t('Download this template'),"icon":"download","fn":_vm.download}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status === 'ready' ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.status === 'ready' ? 'mdi-check' : 'mdi-close')+" ")])]}},{key:"item.lastChanged",fn:function(ref){
var item = ref.item;
return [_c('table-date',{attrs:{"date":item.lastChanged}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.description || '').length > 185 ? ("" + (item.description.length > 185 ? ((item.description.substring(0, 185)) + "...") : item.description)) : item.description)+" ")]}},{key:"item.thumb",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"grey lighten-2 thumb",staticStyle:{"cursor":"pointer"},attrs:{"src":("" + (item.thumb)),"lazy-src":("" + (item.thumb)),"aspect-ratio":"1","width":"70"},on:{"click":function($event){return _vm.viewScreenshot(item)},"error":function($event){return _vm.errorHandlerThumb(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})]}},{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [(item.imageUrl)?_c('v-img',{staticClass:"grey thumb ma-auto",staticStyle:{"cursor":"pointer"},attrs:{"src":("" + (item.imageUrl)),"lazy-src":("" + (item.imageUrl)),"aspect-ratio":"1","width":"70"},on:{"click":function($event){return _vm.viewSeoImage(item)},"error":function($event){return _vm.errorHandlerSeoImage(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_c('span',[_vm._v("—")])]}}],null,true)}),_c('dialogs-update-landing-page',{attrs:{"dialog":_vm.updateLandingPageDialog,"edited-item":_vm.updateLandingPageEditedItem},on:{"close":_vm.updateLandingPageDialogClose}}),_c('dialogs-upload-landing-page',{ref:"uploadLandingPageDialog",on:{"close":_vm.uploadLandingPageDialogClose}}),_c('dialog-image',{ref:"imageZoom"})],1),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }