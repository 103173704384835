<template>
  <dialog-form
    :dialog="dialog"
    :form-title="formTitle"
    :form-scheme="formScheme"
    :form-default-values="{}"
    :save-loading="saveLoading"
    :edited-item="currentItem"
    :submit-text="`Save config`"
    @save="update"
    @close="dialogClose"
  />
</template>

<script>
// @changeEditedItem="changeEditedItem"
import LandingPageImage from '../components/LandingPageImage.vue'

export default {
  name: 'UpdateLandingPage',
  props: {
    dialog: Boolean,
    editedItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    formScheme: {},
    saveLoading: false,
    currentItem: {},
    formTitle: ''
  }),
  watch: {
    dialog () {
      this.currentItem = Object.assign({}, this.editedItem)
    }
  },
  async mounted () {
    this.formTitle = this.$t('Edit template config')
    this.currentItem = Object.assign({}, this.editedItem)
    this.formScheme = {
      dir: {
        type: 'text',
        label: this.$t('System name'),
        // createRules: [this.validate.bind(this, 2, 500)],
        disabled: true,
        md: 4,
        sm: 4
      },
      seoTitle: {
        type: 'text',
        label: this.$t('SEO Title'),
        createRules: 'req|min:2|max:64',
        md: 4,
        sm: 4
      },
      seoImg: {
        type: 'text',
        label: this.$t('SEO Image'),
        md: 4,
        sm: 4
      },
      seoImgPreview: {
        type: 'component',
        component: LandingPageImage,
        md: 12,
        sm: 12
      },
      seoDesc: {
        type: 'textarea',
        label: this.$t('SEO Description'),
        rows: 1,
        'auto-grow': true,
        max: 500,
        min: 2,
        createRules: 'req|min:2|max:500',
        md: 12,
        sm: 12
      },
      status: {
        type: 'switch',
        label: this.$t('The template is ready for full use'),
        falseValue: 'notReady',
        trueValue: 'ready',
        md: 12,
        sm: 12
      }
    }
  },
  methods: {
    async update(evn) {
      try {
        const v = evn.$refs.form.validate()
        if (v) {
          this.saveLoading = true
          const response = await this.$store.dispatch('tasksLpg/saveConfig', { data: this.currentItem })
          if (response.success === true) {
            this.$toast.success(response.messages)
            this.dialogClose()
          } else {
            this.$toast.error(response.errors)
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.saveLoading = false
      }
    },
    dialogClose() {
      this.$emit('close', this)
    }
  }
}
</script>

<style lang="scss">

</style>
