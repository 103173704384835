<template>
  <div>
    <v-img
      class="mx-auto rounded seoImg"
      height="200"
      :src="getSeoImg"
      @error="errorHandler"
    >
      <template v-slot:placeholder>
        <div class="d-flex align-center justify-center fill-height">
          <v-progress-circular
            color="grey-lighten-4"
            indeterminate
          />
        </div>
      </template>
    </v-img>
  </div>
</template>

<script>
export default {
  name: 'LandingPageImage',
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      seoImg: '',
      seoImgOrigin: '',
      withError: false,
      errorInterval: null
    }
  },
  computed: {
    getSeoImg() {
      return this.seoImg
    }
  },
  watch: {
    'item.seoImg'(v) {
      this.seoImg = `${this.item.url}/${v}`
    }
  },
  mounted () {
    this.seoImg = `${this.item.url}/${this.item.seoImg}`
    this.seoImgOrigin = (' ' + this.item.seoImg).slice(1)
  },
  methods: {
    errorHandler(obj) {
      this.seoImg = require('@/assets/img/750x225.png')
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
.v-image {
  border: 1px solid #ebebeb !important;
}
</style>
